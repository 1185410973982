import React from "react";
import { useState, useRef, useMemo } from "react";
import { Editor, EditorState, ContentState } from "draft-js";
import { Selection } from "./Selection";
import { DecoratorFactory } from "./DecoratorFactory";
const HighlightWithinTextarea = React.forwardRef((props, ref) => {
    let { value, onChange, highlight, placeholder = "Enter some text...", selection, } = props;
    const [, forceUpdate] = useState({});
    const myRef = useRef({});
    const decoratorFactory = useRef(new DecoratorFactory());
    let editorState;
    const { prevValue, prevEditorState, nextValue, nextEditorState } = myRef.current;
    if (nextValue == value) {
        // Change was accepted.
        editorState = nextEditorState;
    }
    else if (prevValue == value) {
        // They blocked the state change.
        editorState = prevEditorState;
        if (!selection && nextValue) {
            selection = new Selection(editorState);
            selection.focus = Math.max(selection.anchor, selection.focus);
            selection.anchor = selection.focus;
        }
    }
    else if (prevEditorState) {
        // They chose a whole new value.
        const contentState = ContentState.createFromText(value);
        const changeType = "change-block-data";
        editorState = EditorState.push(prevEditorState, contentState, changeType);
        if (!selection) {
            let fixedValue, offset;
            if (nextEditorState) {
                selection = new Selection(nextEditorState);
                fixedValue = value.replaceAll("\r\n", "\n");
                offset = fixedValue.length - nextValue.length;
            }
            else {
                selection = new Selection(prevEditorState);
                fixedValue = value.replaceAll("\r\n", "\n");
                offset = fixedValue.length - prevValue.length;
            }
            selection.anchor += offset;
            selection.focus += offset;
        }
    }
    else {
        // First time in here.
        const contentState = ContentState.createFromText(value);
        editorState = EditorState.createWithContent(contentState);
    }
    const contentState = editorState.getCurrentContent();
    let decorator;
    decorator = useMemo(() => highlight &&
        decoratorFactory.current.create(contentState, highlight, value), [contentState, highlight, value]);
    editorState = EditorState.set(editorState, {
        decorator: decorator,
    });
    if (selection) {
        editorState = selection.forceSelection(editorState);
    }
    myRef.current = {
        prevEditorState: editorState,
        prevValue: value,
    };
    const onDraftChange = (nextEditorState) => {
        const nextValue = nextEditorState.getCurrentContent().getPlainText();
        myRef.current = Object.assign(Object.assign({}, myRef.current), { nextEditorState: nextEditorState, nextValue: nextValue });
        let selection = undefined;
        selection = new Selection(nextEditorState);
        if (onChange) {
            onChange(nextValue, selection);
        }
        forceUpdate({});
    };
    const newProps = Object.assign({}, props);
    delete newProps.highlight;
    delete newProps.selection;
    delete newProps.placeholder;
    delete newProps.onChange;
    delete newProps.value;
    return (React.createElement(Editor, Object.assign({}, newProps, { editorState: editorState, onChange: onDraftChange, placeholder: placeholder, ref: ref })));
});
export default HighlightWithinTextarea;
