var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _Selection_private;
import { EditorState, SelectionState } from "draft-js";
function editorStateToTextAnchorFocus(editorState) {
    if (!editorState) {
        return { anchor: 0, focus: 0 };
    }
    let contentState = editorState.getCurrentContent();
    let selection = editorState.getSelection();
    let blocks = contentState.getBlocksAsArray();
    let anchorKey = selection.getAnchorKey();
    let anchorOffset = selection.getAnchorOffset();
    let focusKey = selection.getFocusKey();
    let focusOffset = selection.getFocusOffset();
    let blockStart = 0;
    let anchor = undefined;
    let focus = undefined;
    if (anchorKey == "" && focusKey == "") {
        return { anchor: 0, focus: 0 };
    }
    for (const block of blocks) {
        if (block.getKey() == anchorKey) {
            anchor = blockStart + anchorOffset;
        }
        if (block.getKey() == focusKey) {
            focus = blockStart + focusOffset;
        }
        if (anchor != undefined && focus != undefined) {
            break;
        }
        blockStart += block.getLength() + 1;
    }
    if (anchor == undefined || focus == undefined) {
        throw new ReferenceError("Potentially corrupt editorState.");
    }
    return { anchor, focus };
}
function forceSelection(editorState, anchor, focus) {
    if (!editorState) {
        throw new ReferenceError("editorState is required");
    }
    let contentState = editorState.getCurrentContent();
    let blocks = contentState.getBlocksAsArray();
    let blockStart = 0;
    let blockEnd = undefined;
    let anchorKey = undefined;
    let anchorOffset = undefined;
    let focusKey = undefined;
    let focusOffset = undefined;
    let block = undefined;
    for (block of blocks) {
        blockEnd = blockStart + block.getLength();
        if (anchorKey == undefined && blockEnd >= anchor) {
            anchorKey = block.getKey();
            anchorOffset = Math.max(0, anchor - blockStart);
        }
        if (focusKey == undefined && blockEnd >= focus) {
            focusKey = block.getKey();
            focusOffset = Math.max(0, focus - blockStart);
        }
        if (anchorKey != undefined && focusKey != undefined) {
            break;
        }
    }
    if (block == undefined) {
        throw "Unexpected undefined block";
    }
    if (anchorKey == undefined) {
        anchorKey = block.getKey();
        anchorOffset = block.getLength();
    }
    if (focusKey == undefined) {
        focusKey = block.getKey();
        focusOffset = block.getLength();
    }
    let selectionState = SelectionState.createEmpty("");
    selectionState
        .set("anchorKey", anchorKey)
        .set("anchorOffset", anchorOffset)
        .set("focusKey", focusKey)
        .set("focusOffset", focusOffset);
    return EditorState.forceSelection(editorState, selectionState);
}
class Selection {
    constructor(editorStateOrAnchor, focus) {
        _Selection_private.set(this, void 0);
        let editorState = undefined;
        let anchor = -1;
        let initFunc = () => undefined;
        if (typeof editorStateOrAnchor == "number") {
            anchor = editorStateOrAnchor;
            focus = focus == undefined ? anchor : focus;
        }
        else {
            focus = -1; // Silence ts until the below init is called
            editorState = editorStateOrAnchor;
            initFunc = () => {
                __classPrivateFieldSet(this, _Selection_private, Object.assign(Object.assign(Object.assign({}, __classPrivateFieldGet(this, _Selection_private, "f")), editorStateToTextAnchorFocus(__classPrivateFieldGet(this, _Selection_private, "f").editorState)), { init: () => undefined }), "f");
            };
        }
        __classPrivateFieldSet(this, _Selection_private, {
            editorState,
            anchor,
            focus,
            init: initFunc,
        }, "f");
    }
    get anchor() {
        __classPrivateFieldGet(this, _Selection_private, "f").init();
        return __classPrivateFieldGet(this, _Selection_private, "f").anchor;
    }
    set anchor(value) {
        __classPrivateFieldGet(this, _Selection_private, "f").init();
        __classPrivateFieldGet(this, _Selection_private, "f").anchor = value;
    }
    get focus() {
        __classPrivateFieldGet(this, _Selection_private, "f").init();
        return __classPrivateFieldGet(this, _Selection_private, "f").focus;
    }
    set focus(value) {
        __classPrivateFieldGet(this, _Selection_private, "f").init();
        __classPrivateFieldGet(this, _Selection_private, "f").focus = value;
    }
    get start() {
        return Math.min(this.anchor, this.focus);
    }
    set start(value) {
        throw new ReferenceError(`start (${value}) is read only.  use anchor instead`);
    }
    get end() {
        return Math.max(this.anchor, this.focus);
    }
    set end(value) {
        throw new ReferenceError(`end (${value}) is read only.  use focus instead`);
    }
    forceSelection(editorState) {
        return forceSelection(editorState, this.anchor, this.focus);
    }
    getHasFocus() {
        let editorState = __classPrivateFieldGet(this, _Selection_private, "f").editorState;
        return editorState && editorState.getSelection().getHasFocus();
    }
}
_Selection_private = new WeakMap();
export { Selection };
