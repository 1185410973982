import React, { ReactNode, useState, useEffect } from 'react';
import {
	Collapse,
	MenuItem,
	ListItemText,
	ListItemIcon,
} from '@material-ui/core';
import { ExpandLess } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { StyledComponentProps } from '@cuda/bds.ui.styles';
import { NavigationSubmenu } from '@cuda/bds.ui.navigation.navigation-submenu';

/**
 * A child navigation route, for configuring the navigation submenu.
 */
export interface NavigationChildRoute {
	/**
	 *  the name to display in the navbar submenu.
	 */
	name: string;
	/**
	 *  the associated path for this navbar menu item.
	 */
	path?: string;
	/**
	 * disables the navbar button when true.
	 */
	disabled?: boolean;
	/**
	 * child routes, to be shown in a nested submenu.
	 */
	children?: NavigationChildRoute[];
}

/**
 * A navigation route, for configuring the navigation menu.
 */
export interface NavigationRoute {
	/**
	 *  the name to display in the navbar menu.
	 */
	name: string;
	/**
	 *  the associated path for this navbar item.
	 */
	path: string;
	/**
	 *  the icon to display for the navbar button.
	 */
	icon: ReactNode;
	/**
	 * disables the navbar button when true.
	 */
	disabled?: boolean;
	/**
	 * child routes, to be shown from a navigation bar menu when this route is clicked.
	 */
	children?: NavigationChildRoute[];
}

export interface NavigationBarRoutesMenuProps extends StyledComponentProps {
	/**
	 * the navigation route, and its children, that should be displayed in the menu.
	 */
	route: NavigationRoute;
	/**
	 * the current active navigation path.
	 */
	currentPath: string;
	/**
	 * callback called when an item is selected.
	 * @param path the path to navigate to.
	 */
	onNavigate: (path: string) => void;
	/**
	 * callback called when the close icon button is clicked.
	 */
	onClose: () => void;
}

const matchLocation = (basePath: string, pathname: string): boolean => {
	const pattern = new RegExp(`^${basePath}($|[/#?])`);
	return pattern.test(pathname);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		submenuList: {
			margin: 0,
			padding: 0,
			overflowY: 'auto',
			width: '100%',
		},
		menuItem: {
			padding: theme.spacing(1, 2, 1, 1),
			borderRadius: 4,
			color: theme.palette.text.primary,
			'&:hover': {
				backgroundColor:
					// @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
					theme.palette.primary.background || theme.palette.primary.border,
			},
			'&:focus': {
				backgroundColor:
					// @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
					theme.palette.primary.background || theme.palette.primary.border,
			},
		},
		menuItemNested: {
			paddingLeft: theme.spacing(2),
			'& span': {
				fontSize: 12,
				height: 15,
				lineHeight: '15px',
			},
			color: theme.palette.text.secondary,
		},
		menuItemNoClick: {
			cursor: 'default',
			pointerEvents: 'none',
			'&:hover': {
				backgroundColor: 'inherit',
			},
			'&:focus': {
				backgroundColor: 'inherit',
			},
		},
		menuItemActive: {
			backgroundColor:
				// @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
				theme.palette.primary.background || theme.palette.primary.border,
			color: theme.palette.primary.dark,
		},
		menuItemText: {
			fontSize: 14,
			height: 18,
			lineHeight: '18px',
			marginRight: 'auto',
		},
		listItemIcon: {
			minWidth: 20,
		},
		expandIcon: {
			transition: 'transform 0.25s ease-in-out',
		},
		expandIconExpanded: {
			transform: 'rotate(180deg)',
		},
	}),
);

const getPathIndexes = (
	routeChildren?: NavigationChildRoute[],
	currentPath?: string,
): number[] => {
	const index =
		routeChildren &&
		routeChildren.findIndex(subroute => {
			const selfMatchesPath =
				subroute.path && matchLocation(subroute.path, currentPath);
			const childMatchesPath =
				!selfMatchesPath &&
				subroute.children &&
				subroute.children.some(
					subsubroute =>
						subsubroute.path && matchLocation(subsubroute.path, currentPath),
				);
			return subroute.children && (selfMatchesPath || childMatchesPath);
		});
	return index >= 0 ? [index] : [];
};

export function NavigationBarRoutesMenu(
	props: NavigationBarRoutesMenuProps,
): JSX.Element {
	const { route, currentPath, onNavigate, onClose } = props;
	const classes = useStyles(props);
	const [subMenuOpen, setSubMenuOpen] = useState<number[]>(
		getPathIndexes(route?.children, currentPath),
	);
	const toggleSubMenuOpen = (index: number): void => {
		setSubMenuOpen(openMenus =>
			openMenus.includes(index)
				? openMenus.filter(menu => menu !== index)
				: [...openMenus, index],
		);
	};

	useEffect(() => {
		const openIndexes = getPathIndexes(route?.children, currentPath);

		if (openIndexes.length > 0) {
			setSubMenuOpen(openMenus => [...openMenus, ...openIndexes]);
		}
	}, [currentPath]);

	const renderMenuItem = (
		subroute: NavigationChildRoute,
		nested?: boolean,
		onClick?: Function,
		expanded?: boolean,
	): JSX.Element => {
		return (
			<MenuItem
				key={subroute.name}
				onClick={
					subroute.path || onClick
						? (): void => {
								if (subroute.path) onNavigate(subroute.path);
								if (onClick) onClick();
						  }
						: undefined
				}
				disabled={subroute.disabled}
				className={classNames(
					classes.menuItem,
					nested && classes.menuItemNested,
					!subroute.path && !onClick && classes.menuItemNoClick,
					subroute.path &&
						matchLocation(subroute.path, currentPath) &&
						classes.menuItemActive,
				)}
				tabIndex={0}
			>
				<ListItemText classes={{ primary: classes.menuItemText }}>
					{subroute.name}
				</ListItemText>
				{onClick && (
					<ListItemIcon className={classes.listItemIcon}>
						<ExpandLess
							className={classNames(
								classes.expandIcon,
								expanded && classes.expandIconExpanded,
							)}
						/>
					</ListItemIcon>
				)}
			</MenuItem>
		);
	};

	return (
		<NavigationSubmenu title={route.name} onClose={onClose}>
			<ul className={classes.submenuList}>
				{route?.children &&
					route?.children.map((subroute, index) => {
						if (subroute.children) {
							const menuOpen = subMenuOpen.includes(index);
							return (
								<React.Fragment key={subroute.name}>
									{renderMenuItem(
										subroute,
										false,
										() => toggleSubMenuOpen(index),
										menuOpen,
									)}
									<Collapse in={menuOpen}>
										{subroute.children.map(subsubroute =>
											renderMenuItem(subsubroute, true),
										)}
									</Collapse>
								</React.Fragment>
							);
						}
						return renderMenuItem(subroute);
					})}
			</ul>
		</NavigationSubmenu>
	);
}
