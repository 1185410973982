import React from 'react';

import { useApiRequest } from 'src/hooks/useApi';
import { NavigationBar } from '@cuda/bds.ui.navigation.navigation-bar';
import { ProfileAvatar } from '@cuda/bds.ui.navigation.profile-avatar';
import { ProfileMenu } from '@cuda/bds.ui.navigation.profile-menu';
import { BarracudaIcon } from '@cuda/bds.icons.barracuda-icon';
import { useHistory } from 'react-router-dom';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AppLayout from 'src/routes/layouts/AppLayout';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';
import { Typography, Button, Box, Alert } from '@barracuda-internal/bds-core';
import {
	ExitToApp,
	Dashboard,
	Assignment,
	Settings,
	Description,
	Report,
	AccountTreeOutlined,
} from '@material-ui/icons';
import jwtDecode from 'jwt-decode';
import SupportPopup from './SupportPopup';
function SideNav({ children }) {
	const { api } = useAppToolchain();

	const classes = useStyles();
	const history = useHistory();
	const { auth } = useAuth();
	const { config } = useAppToolchain();
	const [refreshDelegate, setRefreshDelegate] = React.useState(0);
	const [resStatus, supportDelegate, error] = useApiRequest(
		() => {
			return api.getSupportDelegateToken();
		},
		[auth, refreshDelegate],
		() => {},
	);

	const [showPopup, setShowPopup] = React.useState(false);
	const [removeAccess, setRemoveAccess] = React.useState(0);

	const [removeAccessStatus, req1, removeAccesserror] = useApiRequest(
		() =>
			removeAccess &&
			api
				.deleteSupportDelegateToken(supportDelegate.id)
				.then((res) => {
					setRefreshDelegate(refreshDelegate + 1);
				})
				.catch((res) => {})
				.finally(() => {}),
		[removeAccess],
		() => {},
	);

	let initials = '';
	if (auth == null) {
		return <div>Waiting for user information.....</div>;
	}
	let accessToken: any = jwtDecode(auth.oidcUser.access_token);
	let act = accessToken.act != undefined;

	if (auth != null && auth.user && auth.user.name) {
		let inarr = auth.user.name.split(' ');
		if (inarr.length > 1) {
			initials =
				inarr[0].trim().substring(0, 1).toUpperCase() +
				inarr[1].trim().substring(0, 1).toUpperCase();
		} else {
			initials = inarr[0].trim().substring(0, 2).toUpperCase();
		}
	}
	const isAdmin = auth.isFullAdmin();
	let mainMenu = [];
	if (isAdmin) {
		mainMenu = [
			{
				path: '/detections',
				icon: <Dashboard />,
				name: 'Detections',
			},
			{
				path: '/auditlog',
				icon: <Assignment />,
				name: 'Audit Logs',
			},
			{
				path: '/scanlog',
				icon: <Description />,
				name: 'Scan Logs',
			},

			// {
			// 	path: '/reports',
			// 	name: 'Reports',
			// 	icon: <Report />,
			// },
			
			{
				path: '/settings',
				name: 'Settings',
				icon: <Settings />,
				children: [
					{
						name: 'Connectors',
						path: '/settings/connectors',
					},
{
						name: 'Policies',
						path: '/settings/policy',
						
					},
					{
						name: 'Classifiers',
						path: '/settings/classifiers',
					},
					{
						name: 'Notifications',
						path: '/settings/Notifications',
					},

					{
						name: 'Users',
						path: '/settings/users',
					},
					{
						name: 'Data',
						path: '/settings/data',
					},
				],
			},
		];
	} else {
		mainMenu = [
			{
				path: '/detections',
				icon: <Dashboard />,
				name: 'Detections',
			},
			{
				path: '/settings',
				name: 'Settings',
				icon: <Settings />,
				children: [
					{
						name: 'Data',
						path: '/settings/data',
					},
				],
			},
		];
	}
	const profileMenus = [];
	if (!act) {
		profileMenus.push({
			name: supportDelegate ? 'Disable Access' : 'Enable Support Access',
			onClick: () => {
				if (supportDelegate) {
					setRemoveAccess(removeAccess + 1);
				} else {
					setShowPopup(true);
				}
			},
			icon: <SupportAgentIcon />,
		});
	}
	profileMenus.push({
		name: 'Log out',
		onClick: () => {
			location.href = config.logoutURL;
		},
		icon: <ExitToApp />,
	});
	return (
		<div
			style={{
				display: 'flex',
				height: '100%',
				width: '100%'
			}}
		>
			<NavigationBar
				title="Data Inspector"
				logo={<BarracudaIcon />}
				options={[
					{
						name: 'Profile',
						icon: (
							<ProfileAvatar size={28}>{initials}</ProfileAvatar>
						),
						menu: (
							<ProfileMenu
								icon={<ProfileAvatar>{initials}</ProfileAvatar>}
								name={auth.user.name}
								email={auth.user.email}
								menuItems={profileMenus}
							/>
						),
					},
				]}
				routes={mainMenu}
				currentPath="/"
				onNavigate={(nav) => {
					history.push(nav);
				}}
			/>
			<div className={classNames(classes['flex'], classes['z_index_0'])} style={{overflow : 'auto'}}>
				<SupportPopup
					show={showPopup}
					closePopup={(refresh) => {
						setShowPopup(false);
						if (refresh) {
							setRefreshDelegate(refreshDelegate + 1);
						}
					}}
				></SupportPopup>
				{supportDelegate && !act ? (
					<Alert severity="warning" className={classNames(classes['align_center'])}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems : 'center'
							}}
						>
							<Typography color="textPrimary">
								Barracuda Support can access your account
							</Typography>
							<Button
								size="small"
								variant="contained"
								color="primary"
								className={classNames(
									classes['ml_2'],
									classes['d_inline'],
								)}
								isLoading={removeAccessStatus === 'RUNNING'}
								onClick={() => {
									setRemoveAccess(removeAccess + 1);
								}}
							>
								Disable Access
							</Button>
						</Box>
					</Alert>
				) : null}

				{act ? (
					<Alert severity="warning" className={classNames(classes['align_center'])}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems : 'center'
							}}
						>
							<Typography color="textPrimary">
								Impersonating {auth.user.name} account
							</Typography>
							<Button
								size="small"
								variant="contained"
								color="primary"
								className={classNames(
									classes['ml_2'],
									classes['d_inline'],
								)}
								onClick={() => {
									location.href = config.logoutURL;
								}}
							>
								Logout
							</Button>
						</Box>
					</Alert>
				) : null}

				<AppLayout noSubHeader={false}>{children}</AppLayout>
			</div>
		</div>
	);
}

export default SideNav;
