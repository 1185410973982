"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var treeitemRole = {
  abstract: false,
  accessibleNameRequired: true,
  baseConcepts: [],
  childrenPresentational: false,
  nameFrom: ['author', 'contents'],
  prohibitedProps: [],
  props: {
    'aria-expanded': null,
    'aria-haspopup': null
  },
  relatedConcepts: [],
  requireContextRole: ['group', 'tree'],
  requiredContextRole: ['group', 'tree'],
  requiredOwnedElements: [],
  requiredProps: {
    'aria-selected': null
  },
  superClass: [['roletype', 'structure', 'section', 'listitem'], ['roletype', 'widget', 'input', 'option']]
};
var _default = treeitemRole;
exports.default = _default;