import React from 'react';
import { Avatar, AvatarProps } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

export interface ProfileAvatarProps extends AvatarProps {
	/**
	 * the size of the avatar (in pixels).
	 */
	size?: number;
}

const useStyles = makeStyles(theme =>
	createStyles({
		profileAvatar: {
			height: (props: ProfileAvatarProps): number => props.size || 40,
			width: (props: ProfileAvatarProps): number => props.size || 40,
			fontSize: (props: ProfileAvatarProps): number =>
				props.size ? props.size / 2 : 20,
			backgroundColor: theme.palette.secondary.main,
		},
	}),
);

export function ProfileAvatar(props: ProfileAvatarProps): JSX.Element {
	const { size, ...avatarProps } = props;
	const classes = useStyles({ size });

	return <Avatar className={classes.profileAvatar} {...avatarProps} />;
}
