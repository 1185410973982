import React, {
	ReactNode,
	useState,
	useEffect,
	useRef,
	useCallback,
} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// TODO: Consume "non-bit" BDS instead of material-ui core?
import { BottomNavigation } from '@material-ui/core';
import { NavigationBarRoutesMenu } from '@cuda/bds.ui.navigation.navigation-bar-routes-menu';
import { NavigationBarRoutesItem } from '@cuda/bds.ui.navigation.navigation-bar-routes-item';
import { NavigationBarRoutes } from '@cuda/bds.ui.navigation.navigation-bar-routes';
import { StyledComponentProps } from '@cuda/bds.ui.styles';
import classNames from 'classnames';
import { NavigationSubmenu } from '@cuda/bds.ui.navigation.navigation-submenu';
import { CrossFade } from '@cuda/bds.ui.functional.cross-fade';

/**
 * A child navigation route, for configuring the navigation submenu.
 */
export interface NavigationChildRoute {
	/**
	 *  the name to display in the navbar submenu.
	 */
	name: string;
	/**
	 *  the associated path for this navbar menu item.
	 */
	path?: string;
	/**
	 * disables the navbar button when true.
	 */
	disabled?: boolean;
	/**
	 * child routes, to be shown in a nested submenu.
	 */
	children?: NavigationChildRoute[];
}

/**
 * A navigation route, for configuring the navigation menu.
 */
export interface NavigationRoute {
	/**
	 *  the name to display in the navbar menu.
	 */
	name: string;
	/**
	 *  the associated path for this navbar item.
	 */
	path: string;
	/**
	 *  the icon to display for the navbar button.
	 */
	icon: ReactNode;
	/**
	 * disables the navbar button when true.
	 */
	disabled?: boolean;
	/**
	 * child routes, to be shown from a navigation bar menu when this route is clicked.
	 */
	children?: NavigationChildRoute[];
}

export interface Options {
	/**
	 *  the name to display in the navbar menu.
	 */
	name: string;
	/**
	 *  the icon to display for the navbar button.
	 */
	icon: ReactNode;
	/**
	 * disables the option button when true.
	 */
	disabled?: boolean;
	/** the content to display in the popout menu when the option is clicked */
	menu: ReactNode;
}

export interface NavigationBarProps extends StyledComponentProps {
	/**
	 * the web app title to display as a tooltip to the logo.
	 */
	title: string;
	/**
	 * the web app logo to display at the top of the nav bar
	 */
	logo: ReactNode;
	/**
	 * click handler for the app logo.
	 * @param event
	 */
	logoOnClick?: () => void;
	/**
	 * additional buttons to show on the navigation bar, such as a logout button.
	 */
	options?: Options[];
	/**
	 * the routes to add as nav bar items. Each provided route should have a unique name and path.
	 * Items provided that are missing a name and/or path will not be rendered.
	 */
	routes?: NavigationRoute[];
	/**
	 * the current active navigation path.
	 */
	currentPath: string;
	/**
	 * callback called when an item is selected.
	 * @param path the path to navigate to.
	 */
	onNavigate: (path: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		topBar: {
			backgroundColor: theme.palette.background.paper,
			boxShadow: 'none',
			zIndex: 1,
			height: '100%',
		},
		siteIcon: {
			height: 40,
			width: 40,
			margin: 4,
			'& svg': {
				width: 24, // TODO: is this needed, or are the supplied icons expected to be 24x24?
				height: 24,
				margin: 8,
			},
		},
		topBarContainer: {
			display: 'flex',
			flexDirection: 'row',
			height: '100%',
		},
		navBar: {
			backgroundColor: theme.palette.background.paper,
			height: '100%',
			flexDirection: 'column',
			width: 48,
			overflowX: 'hidden',
			borderRightColor: '#E2E2E1', // TODO:Does this want tokenizing? Was originally using theme.palette.divider -> colors.uiElement -> #DDDDDD
			borderRightStyle: 'solid',
			borderRightWidth: 1,
		},
		popoverRoot: {
			transition: 'width 1s ease-in-out !important',
		},
		popover: {
			top: '0 !important',
			left: '0 !important',
			maxHeight: '100%',
			height: '100%',
			boxShadow: 'none',
			transition: 'none !important',
		},
		navigationContainer: {
			display: 'flex',
			width: 48,
			height: '100%',
			overflow: 'hidden',
		},
		navigationOptions: {
			display: 'flex',
			flexDirection: 'column',
			width: 48,
			paddingTop: theme.spacing(1.5),
			flexGrow: 1,
		},
		navigationMainMenu: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'start',
			alignItems: 'flex-start',
			overflowX: 'hidden',
			width: '100%',
			height: '100%',
			overflow: 'hidden',
			flexShrink: 0,
			'& > :first-child': {
				marginTop: theme.spacing(2),
				marginBottom: theme.spacing(2),
			},
		},
		navigationSubMenu: {
			display: 'flex',
			flexDirection: 'column',
			width: 0,
			transition: 'width 0.25s, margin-right 0.25s',
			overflow: 'hidden',
			background: 'linear-gradient(88.52deg, #FAFAFA 1.74%, #F7F7F7 99.24%);', // TODO: Tokenize this?
			borderRightColor: '#D4D4D3', // TODO: tokenize this?
			borderRightStyle: 'solid',
			borderRightWidth: 0,
			zIndex: 2,
		},
		navigationSubMenuOpen: {
			width: 292,
			borderRightWidth: 1,
			marginRight: -292,
		},
		clickCapture: {
			position: 'absolute',
			top: 0,
			left: 342,
			width: 'calc(100% - 342px)',
			height: '100%',
			opacity: 0,
			zIndex: 50,
		},
	}),
);

interface SubmenuState {
	open: boolean;
	active?: {
		index: number;
		type: 'route' | 'option';
	};
	last?: {
		index: number;
		type: 'route' | 'option';
	};
}

const matchLocation = (basePath: string, pathname: string): boolean => {
	const pattern = new RegExp(`^${basePath}($|[/#?])`);
	return pattern.test(pathname);
};

export function NavigationBar(props: NavigationBarProps): JSX.Element {
	const {
		routes = [],
		options = [],
		title,
		logo,
		logoOnClick,
		currentPath,
		onNavigate,
	} = props;
	const anchorRef = useRef();
	const [submenuState, setSubmenuState] = useState<SubmenuState>({
		open: false,
	});
	const classes = useStyles(props);
	const activeIndex = routes.findIndex(route =>
		matchLocation(route.path, currentPath),
	);
	const setRouteSubmenu = useCallback(
		(index: number) =>
			setSubmenuState(currentState => ({
				open: true,
				active: {
					index,
					type: 'route',
				},
				last: currentState.active,
			})),
		[setSubmenuState],
	);
	const setOptionSubmenu = useCallback(
		(index: number) =>
			setSubmenuState(currentState => ({
				open: true,
				active: {
					index,
					type: 'option',
				},
				last: currentState.active,
			})),
		[setSubmenuState],
	);
	const setSubmenuClosed = useCallback(
		() =>
			setSubmenuState({
				open: false,
				active: undefined,
				last: undefined,
			}),
		[setSubmenuState],
	);

	useEffect(() => {
		setSubmenuClosed();
	}, [currentPath]);

	return (
		<div className={classes.topBar} ref={anchorRef} id="navbar-root">
			<div className={classes.topBarContainer}>
				<BottomNavigation className={classes.navBar}>
					<div className={classes.navigationContainer}>
						<div className={classes.navigationMainMenu}>
							<NavigationBarRoutesItem
								label={title}
								icon={logo}
								classes={{ navBarItem: classes.siteIcon }}
								onClick={logoOnClick}
							/>
							<NavigationBarRoutes
								routes={routes}
								setSubmenuRoute={(route, index): void => {
									if (route?.children) {
										setRouteSubmenu(index);
									} else {
										setSubmenuClosed();
									}
								}}
								activeIndexes={[activeIndex]}
								focusedIndexes={[
									(submenuState?.active?.type === 'route' &&
										submenuState?.active?.index) ||
										-1,
								]}
								onNavigate={onNavigate}
							/>
						</div>
					</div>
					<div className={classes.navigationOptions}>
						{options.map((option, index) => (
							<NavigationBarRoutesItem
								label={option.name}
								key={option.name}
								onClick={(): void => {
									setOptionSubmenu(index);
								}}
								icon={option.icon}
								focused={
									submenuState?.active?.type === 'option' &&
									index === submenuState?.active?.index
								}
								disabled={option.disabled}
							/>
						))}
					</div>
				</BottomNavigation>
				<div
					className={classNames(
						classes.navigationSubMenu,
						submenuState.open && classes.navigationSubMenuOpen,
					)}
				>
					{submenuState.active && (
						<CrossFade>
							{submenuState.active?.type === 'route' ? (
								<NavigationBarRoutesMenu
									route={routes[submenuState.active.index]}
									currentPath={currentPath}
									onNavigate={onNavigate}
									onClose={setSubmenuClosed}
								/>
							) : (
								<NavigationSubmenu
									title={options[submenuState.active.index].name}
									onClose={setSubmenuClosed}
								>
									{options[submenuState.active.index].menu}
								</NavigationSubmenu>
							)}
						</CrossFade>
					)}
				</div>
				{submenuState.open && (
					<div
						role="presentation"
						className={classes.clickCapture}
						onClick={setSubmenuClosed}
					/>
				)}
			</div>
		</div>
	);
}
