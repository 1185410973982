"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var listitemRole = {
  abstract: false,
  accessibleNameRequired: false,
  baseConcepts: [],
  childrenPresentational: false,
  nameFrom: ['author'],
  prohibitedProps: [],
  props: {
    'aria-level': null,
    'aria-posinset': null,
    'aria-setsize': null
  },
  relatedConcepts: [{
    concept: {
      constraints: ['direct descendant of ol, ul or menu'],
      name: 'li'
    },
    module: 'HTML'
  }, {
    concept: {
      name: 'item'
    },
    module: 'XForms'
  }],
  requireContextRole: ['directory', 'list'],
  requiredContextRole: ['directory', 'list'],
  requiredOwnedElements: [],
  requiredProps: {},
  superClass: [['roletype', 'structure', 'section']]
};
var _default = listitemRole;
exports.default = _default;