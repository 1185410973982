import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import CodeusErrorBoundary from 'src/components/CodeusErrorBoundary';
import useAuth from 'src/hooks/useAuth';
import AppRoute from 'src/routes/AppRoute';
import AuditLogPage from 'src/routes/AuditLogPage';
import ClassifierSettings from 'src/routes/settings/classifiers/ClassifierSettings';
import ConnectorSettings from 'src/routes/settings/ConnectorSettings';
import DataSettings from 'src/routes/settings/DataSettings';
import EmailSettings from 'src/routes/settings/EmailSettings';
import UserSettings from 'src/routes/settings/UserSettings';
import PolicyEngineRoute from './PolicyEngineRoute';


export default function SettingsPage() {
	const { auth } = useAuth();
	if (!auth) return null;
	const isAdmin = auth?.isFullAdmin();

	const redirectUrl = isAdmin ? '/settings/connectors' : '/settings/data';

	const routes = isAdmin ? (
		<Switch>
			<AppRoute
				path="/settings/connectors"
				title="DI Settings: Connectors"
				component={ConnectorSettings}
				requireOnboarding={true}
			/>
			<AppRoute
				component={PolicyEngineRoute}
				path="/settings/policy"
				title="Policy Engine Feeds"
				requireOnboarding={true}
			/>
			<AppRoute
				path="/settings/classifiers"
				title="DI Settings: Classifiers"
				component={ClassifierSettings}
				requireOnboarding={true}
			/>
			<AppRoute
				path="/settings/Notifications"
				title="DI Settings: Notifications"
				component={EmailSettings}
				requireOnboarding={true}
			/>
			<AppRoute
				path="/settings/users"
				title="DI Settings: Users"
				component={UserSettings}
				requireOnboarding={true}
			/>
			<AppRoute
				path="/settings/data"
				title="DI Settings: Data"
				component={DataSettings}
			/>
			<AppRoute
				path="/settings/auditing"
				title="DI Settings: Audit Log"
				component={AuditLogPage}
			/>
			<Redirect to={redirectUrl}></Redirect>
		</Switch>
	) : (
		<Switch>
			<AppRoute
				path="/settings/data"
				title="DI Settings: Data"
				component={DataSettings}
			/>
			<Redirect to={redirectUrl}></Redirect>
		</Switch>
	);

	return (
		<CodeusErrorBoundary>
			{routes}
		</CodeusErrorBoundary>
	);
}
