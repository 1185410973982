import React, { ReactNode, useRef } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useCrossFadeStyles = makeStyles({
	crossFade: {
		transition: 'opacity 0.5s, visibility 0.5s',
		width: '100%',
	},
	upcoming: {
		opacity: 0,
	},
	active: {
		opacity: 1,
	},
	previous: {
		opacity: 0,
		height: 0,
		overflowY: 'visible',
		visibility: 'hidden',
	},
});

export type CrossFadeProps = {
	/**
	 * a node to be rendered in the special component.
	 */
	children: ReactNode | ReactNode[];
};

export function CrossFade(props: CrossFadeProps): JSX.Element {
	const { children } = props;
	const contentQueue = useRef([null]);
	const contentKeys = useRef([1]);
	contentQueue.current = [children, contentQueue.current[0]];
	contentKeys.current = [contentKeys.current[0] + 1, contentKeys.current[0]];
	const classes = useCrossFadeStyles(props);

	return (
		<>
			{[
				<div
					key={contentKeys.current[1]}
					className={classNames(classes.crossFade, classes.previous)}
				>
					{contentQueue.current[1]}
				</div>,
				<div
					key={contentKeys.current[0]}
					className={classNames(classes.crossFade, classes.active)}
				>
					{contentQueue.current[0]}
				</div>,
				<div
					key={contentKeys.current[0] + 1}
					className={classNames(classes.crossFade, classes.upcoming)}
				>
					{null}
				</div>,
			]}
		</>
	);
}
