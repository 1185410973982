"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var sectionRole = {
  abstract: true,
  accessibleNameRequired: false,
  baseConcepts: [],
  childrenPresentational: false,
  nameFrom: [],
  prohibitedProps: [],
  props: {},
  relatedConcepts: [{
    concept: {
      name: 'frontmatter'
    },
    module: 'DTB'
  }, {
    concept: {
      name: 'level'
    },
    module: 'DTB'
  }, {
    concept: {
      name: 'level'
    },
    module: 'SMIL'
  }],
  requireContextRole: [],
  requiredContextRole: [],
  requiredOwnedElements: [],
  requiredProps: {},
  superClass: [['roletype', 'structure']]
};
var _default = sectionRole;
exports.default = _default;