import React, { ReactNode } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { IconButton, Tooltip } from '@material-ui/core';
import { StyledComponentProps } from '@cuda/bds.ui.styles';

export interface NavigationBarRoutesItemProps extends StyledComponentProps {
	/**
	 * if true, the navigation bar is rendered as "active".
	 */
	active?: boolean;
	/**
	 * if true, the navigation bar is rendered as "focused" .
	 */
	focused?: boolean;
	/**
	 * if true, the navigation bar item is rendered as "disabled" (not clickable, and greyed out).
	 */
	disabled?: boolean;
	/**
	 * icon to display for this navigation bar item.
	 */
	icon: ReactNode;
	/**
	 * label text to display for this navigation bar item.
	 */
	label: string;
	/**
	 * url target for the navigation bar item.
	 */
	path?: string;
	/**
	 * on click action for the navigation bar item.
	 */
	onClick?: () => void;
	/**
	 * a unique id to set on the root component
	 */
	id?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		navBarItem: {
			height: 28,
			margin: 10,
			justifyContent: 'flex-start',
			color: theme.palette.text.secondary,
			padding: 0,
			'&:disabled': {
				opacity: 0.3,
			},
			'& svg': {
				width: 20, // TODO: is this needed, or are the supplied icons expected to be 20x20 with 4px margin/padding?
				height: 20,
				margin: 4,
			},
			width: 28,
			'&:hover': {
				backgroundColor:
					// @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
					theme.palette.primary.background || theme.palette.primary.border,
			},
			'&:focus': {
				backgroundColor:
					// @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
					theme.palette.primary.background || theme.palette.primary.border,
			},
		},
		navBarItemFocused: {
			backgroundColor:
				// @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
				theme.palette.primary.background || theme.palette.primary.border,
		},
		myTooltip: {
			backgroundColor: theme.palette.text.primary,
			color: theme.palette.common.white,
			fontSize: 12,
			lineHeight: '125%',
		},
		myArrow: {
			color: theme.palette.text.primary,
		},
		label: {
			textTransform: 'none',
			fontSize: 14,
			textAlign: 'left',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflowX: 'hidden',
		},
		navBarItemActive: {
			color: theme.palette.primary.main,
			backgroundColor:
				// @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
				theme.palette.primary.background || theme.palette.primary.border,
			'& svg & path': {
				fill: 'currentColor',
			},
		},
	}),
);

export function NavigationBarRoutesItem(
	props: NavigationBarRoutesItemProps,
): JSX.Element {
	const { active, onClick, icon, label, disabled, id, focused } = props;
	const classes = useStyles(props);

	return (
		<Tooltip
			title={label}
			classes={{
				tooltip: classes.myTooltip,
				arrow: classes.myArrow,
			}}
			placement="right"
			arrow
		>
			<IconButton
				className={classNames(
					classes.navBarItem,
					active && classes.navBarItemActive,
					focused && classes.navBarItemFocused,
				)}
				onClick={onClick}
				disabled={disabled}
				id={id}
				disableRipple
			>
				{icon}
			</IconButton>
		</Tooltip>
	);
}
