import * as Sentry from '@sentry/react';
import jwtDecode from 'jwt-decode';
import { User as OidcUser } from 'oidc-client';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import { AppToolchain } from 'src/types/applicationTypes';
import { AuthObject } from 'src/types/auth';
import {
	CodeusAccountDetails,
	User,
	UserAssignment
} from 'src/types/dataTypes';
import { isAdminRole } from 'src/utils/auth';
import ulog from 'ulog';

export type RequestStatus = 'IDLE' | 'PENDING' | 'RESOLVED' | 'REJECTED';

export interface AuthStatusProps {
	status: RequestStatus;
	isLongLoad: boolean;
	error?: Error | false;
	auth?: AuthObject;
}

export const AUTH_SESSION_KEY = 'authed';

/**
 * Hook for authorization needs.
 * Changes of status will cause re-renders.
 * Intended for use in high-level components like pages.
 */
export default function useAuth(): AuthStatusProps {
	const toolchain = useAppToolchain();

	const retrievedState = JSON.parse(sessionStorage.getItem(AUTH_SESSION_KEY));
	const lastActiveAccount = localStorage.getItem('lastActiveAccount');

	const [state, _setState] = useState<AuthStatusProps>({
		status: 'IDLE',
		isLongLoad: false,
		auth: retrievedState,
		error: false,
	});

	const setState = (newState: Partial<AuthStatusProps>) => {
		_setState({
			...state,
			...newState,
		});
	};

	if (state?.auth) {
		state.auth.isFullAdmin = () =>
		isAdminRole(
				state.auth.accountDetails.account.id,
				state.auth.assignments,
			)
	}

	// Update user information with third parties
	useEffect(() => {
		// Update user information with Google Analytics
		if (state?.auth?.user && window?.ga) {
			ReactGA.set({ userId: state.auth.user.id });
		}
	}, [state?.auth?.user]);

	// Update user information with sentry
	useEffect(() => {
		if (state?.auth?.user) {
			Sentry.configureScope((scope) =>
				scope.setUser({
					id: state.auth.user.id,
					username: state.auth.user.name,
					email: state.auth.user.email,
				}),
			);
		}
	}, [state?.auth?.user]);

	useEffect(() => {
		if (state.auth) {
			debug('User previously authed. Skipping net.');
			return;
		}

		if (state.status === 'PENDING') {
			debug('Auth check in progress. Skipping net.');
			return;
		}

		setState({ status: 'PENDING' });
		debug('Checking user credentials.');
		runEffects(toolchain)
			.then(([user, accounts, assignments, oidcUser]) => {
				if (!user)
					throw Error('No user received while trying to auth.');
				if (!accounts.length)
					throw Error('No accounts received while trying to auth.');

				const accountDetails =
					lastActiveAccount && accounts
						? accounts.find(
								(a) => a.account.id === lastActiveAccount,
						  ) || accounts[0]
						: accounts[0];

				const auth = {
					user,
					accountDetails,
					accounts,
					assignments,
					oidcUser,
					isFullAdmin: () =>
						isAdminRole(
							accountDetails.account.id,
							assignments,
						),
				};
				sessionStorage.setItem('authed', JSON.stringify(auth));
				localStorage.setItem('hasAuthed', 'true');

				Sentry.configureScope((scope) =>
					scope.setUser({
						id: user.id,
						username: user.name,
						email: user.email,
					}),
				);

				setState({
					auth,
				});
			})
			.catch((e) => {
				if (e.status !== 401) {
					setState({ error: new Error('Authorization failed.') });
					console.error(e);
				} else throw e;
			});
	}, []);

	return state;
}

const runEffects = async (
	toolchain: AppToolchain,
): Promise<[User, CodeusAccountDetails[], UserAssignment[], OidcUser]> => {
	// This has to happen first, because it gives us the token which allows the API to work.
	const oidcUser = await toolchain.oidcUserManager.getUser();

	if (!oidcUser) {
		// FIXME : Haaaaaacks
		const rej = new PromiseRejectionEvent('rejectionhandled', {
			promise: null,
			reason: {
				status: 401,
			},
		});

		throw rej.reason;
	}

	const { api } = toolchain;

	const user = await api.getLoggedInUser();
	debug('User retrieved', user);

	// Retrieve the user's accounts
    const accounts = await api.getUserAccounts(user.id);
    debug('Accounts retrieved', accounts);

    if (!accounts.length) {
        throw new Error("No accounts received while trying to auth.");
    }

	const lastActiveAccount = localStorage.getItem('lastActiveAccount');
    // Determine account details
    const accountDetails = lastActiveAccount && accounts
        ? accounts.find((a) => a.account.id === lastActiveAccount) || accounts[0]
        : accounts[0];
	
    if (!accountDetails) {
        throw new Error("Account details could not be determined.");
    }

    // Retrieve user account assignments for the specific account
    const accountId = accountDetails.account.id;
	let assignments = [];

	try {
		assignments = await api.getUserAccountAssignments(user.id, accountId);
	}catch(err) {
		if(err.response.status == 403) {
			try{
				const data = await api.getAllAccountAssignmentsForUser(user.id);
				if( data.length > 0 ){
					assignments = [data[0]];
				}
			}catch(err){
				
			}
		}
	}
    
    debug('User account assignments retrieved', assignments);

	// const accounts = await ;
	debug('Accounts retrieved', user, accounts, assignments, oidcUser);

	// Setup Intercom
	// if (
	// 	toolchain.config.intercomAppId &&
	// 	Object.values(assignments)
	// 		.filter(
	// 			(a) =>
	// 				a.domain === '/*' ||
	// 				a.domain === `/accounts/${accounts[0].account.id}`,
	// 		)
	// 		.some((a) => a.role === 'role_full_admin')
	// ) {
	window.enableIntercom();
	window.Intercom('boot', {
		app_id: toolchain.config.intercomAppId,
	});
	// }

	ulog.info(`Intercom is ${window.Intercom ? 'loaded' : 'not loaded'}`);

	// Obtain if the user allows us to use real PII
	await api.getUserInfo().then(async (userInfo) => {
		let oidc_access_token_decoded = jwtDecode(oidcUser.access_token);
		let user_hash =
			oidc_access_token_decoded[
				'http://schemas.barracuda.com/datainspector/user_hash'
			];

		let payload = {
			email: '',
			name: 'Data Inspector User',
			user_id: user.id,
			user_hash: user_hash,
			last_request_at: new Date().getTime() / 1000,
			companies: [],
		};

		// use user's real data if in the US
		if (userInfo.clientCountryCode == 'US') {
			debug('User is in the US');
			payload.name = user.name;
			payload.email = user.email;
		}

		//update AuthDB User ID if not found null
		if (user.barracudaId != null) {
			payload["AuthDB_User_ID"] = user.barracudaId;
		}

		// Update user information with Intercom
		if (window?.Intercom) {
			window.Intercom('update', payload);
		}

		debug('Intercom updated', payload);

		// Add account information
		await Promise.all(
			accounts.map(async (account) => {
				debug(
					'Getting account tier state for account',
					account.account,
				);
				payload.companies.push({
					id: account.account.id,
					name: account.account.name,
				});
			}),
		);
	});

	return [user, accounts, assignments, oidcUser];
};

function debug(...args: unknown[]) {
	ulog.debug('AUTH:', ...args);
}
