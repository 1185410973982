/*
 * For some reason, exporting a FunctionComponent
 * doesn't work when importing in codepen.io, so wrap
 * it in a class component.
 */
import * as React from 'react';
import HighlightWithinTextarea from './HighlightWithinTextarea';
export class HighlightWithinTextareaCC extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return React.createElement(HighlightWithinTextarea, Object.assign({}, this.props));
    }
}
