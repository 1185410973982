import { Box, Button } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';
import AddIcon from '@mui/icons-material/Add';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import IdentityGroups from './Identity/IdentityGroups';
import DescriptionInput from './Description';
import IdentityUsers from './Identity/IdentityUsers';
import ExceptionGroups from './Exception/ExceptionGroups';
import ExceptionUsers from './Exception/ExceptionUsers';
import Classifiers from './Classifiers';
import Authorship from './Authorship';
import Actions from './Actions';
import { PolicyContext } from './Main';
import PolicyName from './PolicyName';
import { Policy } from 'src/types/policyTypes';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface PolicySettingsProps {
    anchor: Anchor;
    toggleDrawer: (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
    setPolicyFeeds: React.Dispatch<React.SetStateAction<Policy[]>>;
  }

export const PolicySettings= ({anchor, toggleDrawer, setPolicyFeeds}: PolicySettingsProps) => {
    const classes = useStyles();
    const { api } = useAppToolchain();
    const { auth } = useAuth();
    const [showExceptionList,setShowExceptionList]=useState(false);

    const {selectedPolicyToModify, setSelectedPolicyToModify, prevFeedsResponse, modifyClicked} = useContext(PolicyContext);

    const [policyName, setPolicyName] = useState(selectedPolicyToModify?.id ? selectedPolicyToModify.name : '');
    const [policyNameError, setPolicyNameError] = useState(false);
    const [defaultPolicyNameError, setDefaultPolicyNameError] = useState(false);
    const [policyDescription, setPolicyDescription] = useState(selectedPolicyToModify?.id ? selectedPolicyToModify.description : '');
    const selectedClassifiers = useRef(selectedPolicyToModify?.id ? selectedPolicyToModify.conditions.findingTags : []);
    const [classifierError, setClassifierError] = useState(false);
    const [hideCreate, setHideCreate] = useState(false);
    const [hideSave, setHideSave] = useState(false);

    const selectedInclusionGroups = useRef(
        selectedPolicyToModify?.id ? 
        selectedPolicyToModify.conditions.identity.inclusion.groups :
        []
    );
    const selectedInclusionUsers = useRef(
        selectedPolicyToModify?.id ? 
        selectedPolicyToModify.conditions.identity.inclusion.users :
        []
    );
    const [inclusionError, setInclusionError] = useState(false);

    const selectedExceptGroups = useRef(
        selectedPolicyToModify?.id ? 
        selectedPolicyToModify.conditions.identity.exclusion.groups :
        []
    );
    const selectedExceptUsers = useRef(
        selectedPolicyToModify?.id ? 
        selectedPolicyToModify.conditions.identity.exclusion.users :
        []
    );
    const selectedAuthorship = useRef(
        selectedPolicyToModify?.id ? 
        selectedPolicyToModify.conditions.identity.authorship :
        ["Owner", "Creator", "LastModifier"]
    );
    const [authorshipError, setAuthorshipError] = useState(false);
    const selectedAction = useRef(
        selectedPolicyToModify?.id ? 
        selectedPolicyToModify.actions :
        []
    );

    const handleClickExceptionList=()=>{
        setShowExceptionList(true)
    }

    useEffect(() => {
        setShowExceptionList(() => (selectedPolicyToModify?.conditions?.identity?.exclusion?.groups.length || selectedPolicyToModify?.conditions?.identity?.exclusion?.users.length) ? true : false);
    }, []);

    const InputValidation = () => {
        let validData = true;

        if(policyNameError) {
            validData = false;
        }
        if(defaultPolicyNameError) {
            validData = false;
        }
        if(policyName.length === 0) {
            setPolicyNameError(true);
            validData = false;
        }
        if(selectedClassifiers.current.length === 0) {
            setClassifierError(true);
            validData = false;
        }
        if(selectedInclusionUsers.current.length === 0 && selectedInclusionGroups.current.length === 0) {
            setInclusionError(true);
            validData = false;
        }
        if(selectedAuthorship.current.length === 0) {
            setAuthorshipError(true);
            validData = false;
        }
        return validData;
    }

    const handleSave = (e) => {
        if(!InputValidation()) {
            return;
        }
        setHideSave(true);
        const reqPayload = {
            "name": policyName,
            "description": policyDescription.length === 0 ? "-" : policyDescription,
            "priority": selectedPolicyToModify.priority,
            "conditions": {
                "identity": {
                    "inclusion": {
                        "users": selectedInclusionUsers.current,
                        "groups": selectedInclusionGroups.current,
                    },
                    "exclusion": {
                        "users": selectedExceptUsers.current,
                        "groups": selectedExceptGroups.current,
                    },
                    "authorship": selectedAuthorship.current,
                },
                "findingTags": selectedClassifiers.current,
            },
            "actions": selectedAction.current,
            "createdBy": selectedPolicyToModify.createdBy,
            "disabled": selectedPolicyToModify.disabled
        };
        api.updatePolicyFeed(
            auth.accountDetails.account.id,
            reqPayload,
            selectedPolicyToModify.id
        ).then(({data}) => {
            if(data) {
                setPolicyFeeds(prev => {
                    const updatedData = prev.map(feed => {
                        if(feed.id === data.id) {
                            return {...data}; 
                        } else {
                            return feed;
                        }
                    });
                    prevFeedsResponse.current = updatedData;
                    return updatedData;
                });
                setSelectedPolicyToModify({});
                toggleDrawer('right', false)(e);
            }
        })
        .catch(error => {
            console.error('Error on modifying the policy:', error);
            setHideSave(false);
        });
    }

    const handleCreate = (e) => { 
        if(!InputValidation()) {
            return;
        }
        setHideCreate(true);
        const reqPayload = {
            "name": policyName,
            "description": policyDescription.length === 0 ? "-" : policyDescription,
            "priority": 1,
            "conditions": {
                "identity": {
                    "inclusion": {
                        "users": selectedInclusionUsers.current,
                        "groups": selectedInclusionGroups.current,
                    },
                    "exclusion": {
                        "users": selectedExceptUsers.current,
                        "groups": selectedExceptGroups.current,
                    },
                    "authorship": selectedAuthorship.current,
                },
                "findingTags": selectedClassifiers.current,
            },
            "actions": selectedAction.current,
            "disabled": true
        };

        api.createPolicy(
            auth.accountDetails.account.id,
            reqPayload
        ).then(res => {
            if(res) {
                setPolicyFeeds(prev => {
                    let updatedFeeds = prev.map((feed) => {
                        return {
                            ...feed,
                            priority: feed.priority + 1
                        };
                    });
                    const priorityChange = updatedFeeds.reduce((acc, curr) => {
                          acc.push({
                              id: curr.id,
                              priority: curr.priority
                            }) 
                        return acc;
                    }, []);

                    api.updatePolicyEngineFeedPriorities(
                        auth.accountDetails.account.id,
                        priorityChange
                    );
                    updatedFeeds = [res, ...updatedFeeds]
                    prevFeedsResponse.current = updatedFeeds;
                    return updatedFeeds; 
                });

                toggleDrawer('right', false)(e); 
            }
        })
        .catch(error => {
            console.error('Error on creating the policy:', error);
            setHideCreate(false);
        });
    }


    return(
            <>
              <Box
                sx={{width: 1050}}
                style={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowX: 'scroll',
                }}
                role="presentation"
                className={classes['greyBackground']}>

                <div style={{margin: '24px', display: 'flex', flexDirection: 'column', background: 'white', padding: '20px', marginBottom: '20px'}}>
                    <PolicyName limit={60} labelTxt={'Policy name'} variant={'small'} value={policyName} setValue={setPolicyName} 
                    error={policyNameError} setError={setPolicyNameError} isDefault={selectedPolicyToModify?.isDefault} defaultError={defaultPolicyNameError} setDefaultError={setDefaultPolicyNameError} />

                    <DescriptionInput limit={120} labelTxt={'Description (optional)'} variant={'medium'} value={policyDescription} setValue={setPolicyDescription} />
                </div>

                <div style={{margin: '5px 24px', display: 'flex', flexDirection: 'column', background: 'white', padding: '20px'}}>
                    <div style={{fontSize: '10px', fontWeight: 500, color: 'gray'}}>Condition</div>
                    <div style={{display: 'flex', alignItems: 'center', width: '100%', margin: '7px 0px'}}>
                        <span style={{width: '8%', textAlign: 'center', color: 'gray'}}>IF</span>
                        <span style={{width: '15%', textAlign: 'center', fontWeight: 500}}>Classifier</span>
                        <span style={{width: '4%', textAlign: 'center'}}>is</span>
                        <div style={{flex: 1}}>
                            <Classifiers selectedClassifiers={selectedClassifiers} error={classifierError} setError={setClassifierError}/>
                        </div>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', width: '100%', margin: '7px 0px'}}>
                        <span style={{width: '8%', textAlign: 'center', color: 'gray'}}>AND</span>
                        <span style={{width: '15%', textAlign: 'center', fontWeight: 500}}>Identity</span>
                        <span style={{width: '4%', textAlign: 'center'}}>is</span>
                        <div style={{flex: 1, display: 'flex', alignItems: 'center', gap: '10px'}}>
                            <div style={{flex: 1}}>
                                <IdentityGroups selectedGroups={selectedInclusionGroups} setError={setInclusionError} selectedUsers={selectedInclusionUsers} />
                            </div>
                            <div style={{flex: 1}}>
                                <IdentityUsers selectedUsers={selectedInclusionUsers} setError={setInclusionError} selectedGroups={selectedInclusionGroups} />
                            </div>
                        </div>
                    </div>
                    {inclusionError && <span style={{color: '#d32f2f', alignSelf: 'center'}}>Atleast a group or user must be selected on Identity</span>}
                    <div style={{display: 'flex', alignItems: 'center', width: '100%', margin: '7px 0px'}}>
                        <Button style={{width: '27%'}} color="primary" onClick={handleClickExceptionList}><AddIcon fontSize='small' 
                            className={classNames(classes['mr_1'])} /> Except</Button>
                        {showExceptionList && <div style={{flex: 1, display: 'flex', alignItems: 'center', gap: '10px'}}>
                            <div style={{flex: 1}}>
                                <ExceptionGroups selectedGroups={selectedExceptGroups}/>
                            </div>
                            <div style={{flex: 1}}>
                                <ExceptionUsers selectedUsers={selectedExceptUsers}/>
                            </div>
                        </div>}
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', width: '100%', margin: '7px 0px'}}>
                        <span style={{width: '8%', textAlign: 'center', color: 'gray'}}>AND</span>
                        <span style={{width: '15%', textAlign: 'center', fontWeight: 500}}>Authorship of file</span>
                        <span style={{width: '4%', textAlign: 'center'}}>is</span>
                        <div style={{flex: 1}}>
                            <Authorship selectedAuthorship={selectedAuthorship} error={authorshipError} setError={setAuthorshipError} />
                        </div>
                    </div>
                </div>

                <div style={{margin: '24px', display: 'flex', flexDirection: 'column', background: 'white', padding: '20px'}}>
                    <div style={{fontSize: '10px', fontWeight: 500, color: 'gray'}}>Action</div>
                    <div style={{display: 'flex', alignItems: 'center', width: '100%', margin: '7px 0px'}}>
                        <span style={{width: '8%', textAlign: 'center', color: 'gray'}}>THEN</span>
                        <div style={{width: '40%'}}>
                            <Actions selectedAction={selectedAction} />
                        </div>
                    </div>
                </div>

                <div style={{marginTop: 'auto', background:"white", display: 'flex', padding: '15px 25px'}}>
                    <Button style={{marginLeft: 'auto', marginRight: '25px'}} color="primary" onClick={toggleDrawer(anchor, false)}>Cancel</Button>
                    {!modifyClicked && 
                    <Button style={{marginRight: '75px', visibility: `${hideCreate ? 'hidden': 'visible'}`}}
                        color="primary"
                        variant='contained'
                        onClick={handleCreate}>Create
                        </Button>}
                    {modifyClicked && 
                    <Button style={{marginRight: '75px', visibility: `${hideSave ? 'hidden': 'visible'}`}}
                        onClick={(e) => handleSave(e)}
                        color="primary" 
                        variant='contained'>
                        Save
                    </Button>}
                </div>
              </Box>
            </>
    )

  }