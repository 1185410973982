import React, { ReactNode } from 'react';
import { Typography, IconButton } from '@material-ui/core';
import { StyledComponentProps } from '@cuda/bds.ui.styles';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export interface NavigationSubmenuProps extends StyledComponentProps {
	/**
	 * title for the submenu.
	 */
	title: string;
	/**
	 * callback called when the close icon button is clicked.
	 */
	onClose: () => void;
	/**
	 * content to be rendered in the submenu.
	 */
	children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		submenuBar: {
			width: 292,
			height: '100%',
			maxHeight: '100%',
			boxShadow: 'none',
			padding: 0,
		},
		title: {
			height: 28,
			display: 'flex',
			alignItems: 'center',
			fontSize: 16,
			lineHeight: 0.95,
			fontWeight: 600,
			margin: theme.spacing(3, 2, 1.25),
			color: theme.palette.secondary.dark,
			cursor: 'default',
		},
		closeIconButton: {
			height: 24,
			margin: theme.spacing(1.25, 1, 1.25, 1.25),
			justifyContent: 'flex-start',
			color: theme.palette.text.secondary,
			padding: 0,
			'&:disabled': {
				opacity: 0.3,
			},
			'& svg': {
				width: 24,
				height: 24,
			},
			width: 24,
			'&:hover': {
				backgroundColor:
					// @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
					theme.palette.primary.background || theme.palette.primary.border,
			},
			'&:focus': {
				backgroundColor:
					// @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
					theme.palette.primary.background || theme.palette.primary.border,
			},
			marginLeft: 'auto',
		},
		submenuContainer: {
			display: 'flex',
			flexDirection: 'row',
			width: `calc(100% - ${theme.spacing(2)}px)`,
			padding: 0,
			margin: theme.spacing(0, 1),
		},
	}),
);

export function NavigationSubmenu(props: NavigationSubmenuProps): JSX.Element {
	const { children, title, onClose } = props;
	const classes = useStyles(props);

	return (
		<div className={classes.submenuBar}>
			<Typography variant="h4" component="div" className={classes.title}>
				{title}
				<IconButton className={classes.closeIconButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</Typography>
			<div className={classes.submenuContainer}>{children}</div>
		</div>
	);
}
