import { Button, CircularProgress, colors } from '@barracuda-internal/bds-core';
import DropDownArrow from '@barracuda-internal/bds-core/dist/Icons/Controls/DropDownArrow';
import SearchIcon from '@barracuda-internal/bds-core/dist/Icons/Core/Search';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import {
    bindMenu,
    bindToggle,
    usePopupState
} from 'material-ui-popup-state/hooks';
import React, { forwardRef, useState } from 'react';
import { BCCAccount } from 'src/components/BCC/BCCAccountToolbar';
import HighlightText from './HighlightText';
import styles from './styles';

export interface BCCAccountsButtonProps {
	className?: string;
	isLoading?: boolean;
	isValidating?: boolean;
	edge?: 'start' | 'end' | false;
	accounts: Array<BCCAccount>;
	onAccountChange?: (newAccountId: string) => void;
	currentAccountId?: string;
	defaultAccountId?: string;
}

export const BCCAccountsButton = forwardRef<null, BCCAccountsButtonProps>(
	(props, ref): React.ReactElement => {
		const {
			className = '',
			isLoading = false,
			isValidating = false,
			edge = false,
			accounts,
			onAccountChange,
			currentAccountId,
			defaultAccountId,
		} = props;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const classes: any = styles();

		const popupState = usePopupState({
			variant: 'popover',
			popupId: 'BCCAccountsButton',
		});

		const current =
			accounts &&
			accounts.find(
				(a: BCCAccount): boolean => a.id === currentAccountId,
			);

		const [filterString, setFilterString] = useState('');

		return (
			<>
				<Button
					{...bindToggle(popupState)}
					ref={ref}
					color="inherit"
					variant="text"
					aria-label="account-switcher"
					className={classNames(className, {
						[classes.edgeStart]: edge === 'start',
						[classes.edgeEnd]: edge === 'end',
					})}
					style={{
						textTransform: 'none',
						color: colors.backgroundLight,
					}}
					size="small"
					endIcon={
						isLoading ? (
							<CircularProgress
								size={10}
								style={{ marginLeft: 10 }}
							/>
						) : (
							<DropDownArrow />
						)
					}
					disabled={isLoading}
				>
					<b>Account:</b> &nbsp; {(current && current.name) || '...'}
				</Button>
				<Menu
					{...bindMenu(popupState)}
					transitionDuration={0}
					marginThreshold={4}
					anchorOrigin={{ horizontal: -11, vertical: 0 }}
					getContentAnchorEl={null}
					MenuListProps={{ disablePadding: true, dense: true }}
				>
					<MenuItem
						key="top"
						{...bindToggle(popupState)}
						style={{
							background: colors.backgroundLight,
							// TODO: figure out how to make this match the button font size
							// fontSize: '0.8125rem',
						}}
					>
						<b>Account:</b> &nbsp;{' '}
						{(current && current.name) || '...'}
						<ListItemSecondaryAction>
							{!isValidating ? null : (
								<CircularProgress
									size={20}
									style={{ marginLeft: 10 }}
								/>
							)}
						</ListItemSecondaryAction>
					</MenuItem>
					{isLoading ? (
						[]
					) : (
						<div>
							<Divider />
							<MenuItem key="filter">
								<InputBase
									placeholder="Search"
									fullWidth
									margin="dense"
									value={filterString}
									onChange={(
										event: React.ChangeEvent<HTMLInputElement>,
									): void =>
										setFilterString(event.target.value)
									}
									startAdornment={
										<InputAdornment position="start">
											<SearchIcon
												fontSize="small"
												color="primary"
											/>
										</InputAdornment>
									}
								/>
							</MenuItem>
							<Divider />
							<ListSubheader>Accounts</ListSubheader>
							{accounts &&
								accounts
									.filter(
										(a: BCCAccount) =>
											!filterString.length ||
											a.name
												.toLocaleLowerCase()
												.indexOf(
													filterString.toLocaleLowerCase(),
												) !== -1,
									)
									.map((account: BCCAccount) => (
										<MenuItem
											key={account.id}
											onClick={(): void => {
												// call this one if it exists
												if (onAccountChange) {
													onAccountChange(account.id);
												}
												popupState.close();
											}}
										>
											<HighlightText
												fullText={account.name}
												matchText={filterString}
											/>
											{account.id === defaultAccountId ? (
												<span
													style={{
														color: colors.barracudaBlack50,
													}}
												>
													&nbsp;(Default)
												</span>
											) : null}
										</MenuItem>
									))}
						</div>
					)}
				</Menu>
			
			</>
		);
	},
);

export default BCCAccountsButton;
