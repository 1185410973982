import React, { ReactNode } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { NavigationBarRoutesItem } from '@cuda/bds.ui.navigation.navigation-bar-routes-item';
import { StyledComponentProps } from '@cuda/bds.ui.styles';

/**
 * A child navigation route, for configuring the navigation submenu.
 */
export interface NavigationChildRoute {
	/**
	 *  the name to display in the navbar submenu.
	 */
	name: string;
	/**
	 *  the associated path for this navbar menu item.
	 */
	path?: string;
	/**
	 * disables the navbar button when true.
	 */
	disabled?: boolean;
	/**
	 * child routes, to be shown in a nested submenu.
	 */
	children?: NavigationChildRoute[];
}

/**
 * A navigation route, for configuring the navigation menu.
 */
export interface NavigationRoute extends StyledComponentProps {
	/**
	 *  the name to display in the navbar menu.
	 */
	name: string;
	/**
	 *  the associated path for this navbar item.
	 */
	path: string;
	/**
	 *  the icon to display for the navbar button.
	 */
	icon: ReactNode;
	/**
	 * disables the navbar button when true.
	 */
	disabled?: boolean;
	/**
	 * child routes, to be shown from a navigation bar menu when this route is clicked.
	 */
	children?: NavigationChildRoute[];
}

const useStyles = makeStyles(
	createStyles({
		navBarRouter: {
			display: 'flex',
			flexDirection: 'row',
		},
		navBarRoutes: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
		},
	}),
);

export type NavigationBarRoutesProps = {
	/**
	 * the routes to add as nav bar items. Each provided route should have a unique name and path. Items provided that are missing a name and/or path will not be rendered.
	 */
	routes: NavigationRoute[];
	/**
	 * callback called when a navigation item that has children is clicked.
	 * @param route
	 */
	setSubmenuRoute: (route: NavigationRoute, index: number) => void;
	/**
	 * indexes of the current active route/s.
	 */
	activeIndexes: number[];
	/**
	 * indexes of the current focused route/s.
	 */
	focusedIndexes: number[];
	/**
	 * callback called when a route is clicked that has a path and no children.
	 * @param path the path of the route that was clicked
	 */
	onNavigate: (path: string) => void;
};

export function NavigationBarRoutes(
	props: NavigationBarRoutesProps,
): JSX.Element {
	const { routes, setSubmenuRoute, activeIndexes, focusedIndexes, onNavigate } =
		props;
	const classes = useStyles({ ...props, activeIndexes });

	return (
		<div className={classes.navBarRouter}>
			<div className={classes.navBarRoutes}>
				{routes.map((route, index) => (
					<NavigationBarRoutesItem
						label={route.name}
						key={route.path}
						onClick={(): void => {
							setSubmenuRoute(route, index);
							if (!route.children && route.path) {
								onNavigate(route.path);
							}
						}}
						icon={route.icon}
						active={activeIndexes.includes(index)}
						focused={focusedIndexes.includes(index)}
						disabled={route.disabled}
					/>
				))}
			</div>
		</div>
	);
}
