"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var dom = [['a', {
  reserved: false
}], ['abbr', {
  reserved: false
}], ['acronym', {
  reserved: false
}], ['address', {
  reserved: false
}], ['applet', {
  reserved: false
}], ['area', {
  reserved: false
}], ['article', {
  reserved: false
}], ['aside', {
  reserved: false
}], ['audio', {
  reserved: false
}], ['b', {
  reserved: false
}], ['base', {
  reserved: true
}], ['bdi', {
  reserved: false
}], ['bdo', {
  reserved: false
}], ['big', {
  reserved: false
}], ['blink', {
  reserved: false
}], ['blockquote', {
  reserved: false
}], ['body', {
  reserved: false
}], ['br', {
  reserved: false
}], ['button', {
  reserved: false
}], ['canvas', {
  reserved: false
}], ['caption', {
  reserved: false
}], ['center', {
  reserved: false
}], ['cite', {
  reserved: false
}], ['code', {
  reserved: false
}], ['col', {
  reserved: true
}], ['colgroup', {
  reserved: true
}], ['content', {
  reserved: false
}], ['data', {
  reserved: false
}], ['datalist', {
  reserved: false
}], ['dd', {
  reserved: false
}], ['del', {
  reserved: false
}], ['details', {
  reserved: false
}], ['dfn', {
  reserved: false
}], ['dialog', {
  reserved: false
}], ['dir', {
  reserved: false
}], ['div', {
  reserved: false
}], ['dl', {
  reserved: false
}], ['dt', {
  reserved: false
}], ['em', {
  reserved: false
}], ['embed', {
  reserved: false
}], ['fieldset', {
  reserved: false
}], ['figcaption', {
  reserved: false
}], ['figure', {
  reserved: false
}], ['font', {
  reserved: false
}], ['footer', {
  reserved: false
}], ['form', {
  reserved: false
}], ['frame', {
  reserved: false
}], ['frameset', {
  reserved: false
}], ['h1', {
  reserved: false
}], ['h2', {
  reserved: false
}], ['h3', {
  reserved: false
}], ['h4', {
  reserved: false
}], ['h5', {
  reserved: false
}], ['h6', {
  reserved: false
}], ['head', {
  reserved: true
}], ['header', {
  reserved: false
}], ['hgroup', {
  reserved: false
}], ['hr', {
  reserved: false
}], ['html', {
  reserved: true
}], ['i', {
  reserved: false
}], ['iframe', {
  reserved: false
}], ['img', {
  reserved: false
}], ['input', {
  reserved: false
}], ['ins', {
  reserved: false
}], ['kbd', {
  reserved: false
}], ['keygen', {
  reserved: false
}], ['label', {
  reserved: false
}], ['legend', {
  reserved: false
}], ['li', {
  reserved: false
}], ['link', {
  reserved: true
}], ['main', {
  reserved: false
}], ['map', {
  reserved: false
}], ['mark', {
  reserved: false
}], ['marquee', {
  reserved: false
}], ['menu', {
  reserved: false
}], ['menuitem', {
  reserved: false
}], ['meta', {
  reserved: true
}], ['meter', {
  reserved: false
}], ['nav', {
  reserved: false
}], ['noembed', {
  reserved: true
}], ['noscript', {
  reserved: true
}], ['object', {
  reserved: false
}], ['ol', {
  reserved: false
}], ['optgroup', {
  reserved: false
}], ['option', {
  reserved: false
}], ['output', {
  reserved: false
}], ['p', {
  reserved: false
}], ['param', {
  reserved: true
}], ['picture', {
  reserved: true
}], ['pre', {
  reserved: false
}], ['progress', {
  reserved: false
}], ['q', {
  reserved: false
}], ['rp', {
  reserved: false
}], ['rt', {
  reserved: false
}], ['rtc', {
  reserved: false
}], ['ruby', {
  reserved: false
}], ['s', {
  reserved: false
}], ['samp', {
  reserved: false
}], ['script', {
  reserved: true
}], ['section', {
  reserved: false
}], ['select', {
  reserved: false
}], ['small', {
  reserved: false
}], ['source', {
  reserved: true
}], ['spacer', {
  reserved: false
}], ['span', {
  reserved: false
}], ['strike', {
  reserved: false
}], ['strong', {
  reserved: false
}], ['style', {
  reserved: true
}], ['sub', {
  reserved: false
}], ['summary', {
  reserved: false
}], ['sup', {
  reserved: false
}], ['table', {
  reserved: false
}], ['tbody', {
  reserved: false
}], ['td', {
  reserved: false
}], ['textarea', {
  reserved: false
}], ['tfoot', {
  reserved: false
}], ['th', {
  reserved: false
}], ['thead', {
  reserved: false
}], ['time', {
  reserved: false
}], ['title', {
  reserved: true
}], ['tr', {
  reserved: false
}], ['track', {
  reserved: true
}], ['tt', {
  reserved: false
}], ['u', {
  reserved: false
}], ['ul', {
  reserved: false
}], ['var', {
  reserved: false
}], ['video', {
  reserved: false
}], ['wbr', {
  reserved: false
}], ['xmp', {
  reserved: false
}]];
var domMap = {
  entries: function entries() {
    return dom;
  },
  get: function get(key) {
    var item = dom.find(function (tuple) {
      return tuple[0] === key ? true : false;
    });
    return item && item[1];
  },
  has: function has(key) {
    return !!this.get(key);
  },
  keys: function keys() {
    return dom.map(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          key = _ref2[0];

      return key;
    });
  },
  values: function values() {
    return dom.map(function (_ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
          values = _ref4[1];

      return values;
    });
  }
};
var _default = domMap;
exports.default = _default;