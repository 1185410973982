import { colors } from '@barracuda-internal/bds-core';
import React from 'react';

export interface BDSHighlightTextProps {
	fullText: string;
	matchText: string;
}

/**
 * React Component Class for highlighting text for search matches and such.
 */
const BDSHighlightText: React.FC<BDSHighlightTextProps> = (
	props: BDSHighlightTextProps,
): React.ReactElement => {
	const { fullText, matchText } = props;

	const matchStartIndex = fullText
		.toLowerCase()
		.indexOf(matchText.toLowerCase());

	if (matchText && matchStartIndex !== -1) {
		const matchEndIndex = matchStartIndex + matchText.length;

		return (
			<span>
				{fullText.substring(0, matchStartIndex)}
				<span style={{ backgroundColor: colors.sunriseYellow }}>
					{fullText.substring(matchStartIndex, matchEndIndex)}
				</span>
				{fullText.substring(matchEndIndex)}
			</span>
		);
	}

	return <span>{fullText}</span>;
};

export default BDSHighlightText;
