import {
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
	Typography,
	Checkbox,
} from '@barracuda-internal/bds-core';
import React, { ReactElement, useState } from 'react';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import Grid from '@material-ui/core/Grid';
import useAuth from 'src/hooks/useAuth';
import { Alert } from '@material-ui/lab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DateTime } from 'luxon';
import useStyles from 'src/styles/di-theme';
interface Props {}

function SavedData({}: Props): ReactElement {
	const { auth } = useAuth();
	const { api } = useAppToolchain();
	const [doDelete, setDoDelete] = useState(false);
	const [doCancelDelete, setDoCancelDelete] = useState(false);
	const [hasDeleteJob, setHasDeleteJob] = useState(true);
	const [warnWin, setWarnWin] = useState(false);
	const [acceptWarn, setAcceptWarn] = useState(false);
	const [isAllowed, setIsAllowed] = useState(false);

	useApiRequest(
		auth &&
			((signal) =>
				api.getAccountAssignment(auth.accountDetails.account.id, {
					obj: 'obj_all',
					operation: 'delete',
				})),
		[auth],
		([status, resp]) => {
			if (resp && resp.data) {
				let currentUserId = auth.user.id;
				let assignments = resp.data;
				assignments.forEach((ass) => {
					if (ass.subject == currentUserId) {
						setIsAllowed(true);
					}
				});
			}
		},
	);
	const [jobStatus, jd] = useApiRequest(
		auth &&
			hasDeleteJob &&
			(() => api.getDeleteAccJob(auth.accountDetails.account.id)),
		[auth, hasDeleteJob],
		(jobData) => {
			setHasDeleteJob(false);
		},
	);
	const onClose = () => {
		setWarnWin(false);
	};
	const classes = useStyles();
	const [updateStatus, req, error] = useApiRequest(
		doDelete &&
			(() =>
				api.deleteAccount(auth.accountDetails.account.id, {
					delay: '00.00:05:00',
				})),
		[doDelete],
		() => {
			setWarnWin(false);
			setDoDelete(false);
			setHasDeleteJob(true);
		},
	);
	const [cancelJobStatus] = useApiRequest(
		doCancelDelete &&
			(() => api.cancelDeleteAccJob(auth.accountDetails.account.id)),
		[doCancelDelete],
		() => {
			setDoCancelDelete(false);
			setHasDeleteJob(true);
		},
	);

	if (!isAllowed) {
		return <></>;
	}

	return (
		<>
			<Dialog open={warnWin} onClose={onClose}>
				<DialogTitle>
					<Typography variant="h2">Delete saved Data?</Typography>
				</DialogTitle>
				<DialogContent>
					{error ? (
						<Alert severity="error" className={classes['mb_3']}>
							There was an error deleting the account. Please try
							again.
						</Alert>
					) : null}
					<Alert  severity='warning' className={classes['mb_3']} >
						You are about to delete inspector meta-data, redactions
						and authorization keys to your source data
					</Alert>
					<FormControlLabel
						className={classes['mb_3']}
						control={
							<Checkbox
								checked={acceptWarn}
								onChange={(e, checked) =>
									setAcceptWarn(checked)
								}
							/>
						}
						label="I understand that deleting saved data is permanent and cannot be undone after 24 hours and that I will now be immediately logged out."
					/>
				</DialogContent>
				<DialogActions>
					<Button
						isLoading={updateStatus === 'RUNNING'}
						color="primary"
						onClick={() => setWarnWin(false)}
					>
						Cancel
					</Button>
					<Button
						isLoading={updateStatus === 'RUNNING'}
						color="primary"
						variant="contained"
						disabled={!acceptWarn}
						onClick={() => {
							setDoDelete(true);
						}}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<div>
				<Typography
					variant="h3"
					color="textPrimary"
					className={classes['mb_4']}
				>
					Saved Data
				</Typography>
				<div className={classes['ml_2']}>
					<Typography
						variant="h4"
						color="textPrimary"
						className={classes['mb_4']}
					>
						Offboard inspector data
					</Typography>
					{jobStatus == 'RESOLVED' ? (
						<Alert className={classes['mb_2']} severity="error">
							Deletion process is set to start at &nbsp;
							{DateTime.fromISO(jd.deleteTime).toLocaleString(
								DateTime.DATE_FULL,
							)} &nbsp;
							{DateTime.fromISO(jd.deleteTime).toLocaleString(
								DateTime.TIME_WITH_SHORT_OFFSET,
							)}
							<Button
								size="small"
								onClick={() => {
									setDoCancelDelete(true);
								}}
							>
								Stop deletion
							</Button>
						</Alert>
					) : null}
					<Grid container spacing={1} alignItems="flex-start">
						<Grid item xs={3}>
							<Button
								variant="outlined"
								onClick={() => {
									setWarnWin(true);
								}}
								disabled={jobStatus == 'RESOLVED' }
								color="secondary"
							>
								Delete
							</Button>
						</Grid>
						<Grid item xs={9}>
							<Typography variant="body1" color="textPrimary">
								Offboarding will delete inspector meta-data,
								redactions and authorization keys to your source
								data. Upon deletion, you will be logged out and
								have a 24 hour grace period to log back in and
								stop the deletion process.
							</Typography>

							<Typography
								variant="body1"
								color="textPrimary"
								className={classes['mt_2']}
							>
								Deleting inspector data will have no impact on
								the original source data.
							</Typography>
						</Grid>
					</Grid>
				</div>
			</div>
		</>
	);
}

export default SavedData;
