import React, { ReactNode } from 'react';
import {
	ListItemIcon,
	ListItemText,
	MenuItem,
	Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export type ProfileMenuProps = {
	/**
	 * the current user's name.
	 */
	name?: string;
	/**
	 * the current user's email.
	 */
	email?: string;
	/**
	 * icon representing the current user.
	 */
	icon?: ReactNode;
	/**
	 * menu items to render.
	 */
	menuItems?: {
		/**
		 * name of the menu item.
		 */
		name: string;
		/**
		 * on click event handler for menu item.
		 */
		onClick: () => void;
		/**
		 * menu item is disable if true.
		 */
		disabled?: boolean;
		/**
		 * icon to display in front of the name.
		 */
		icon?: ReactNode;
		/**
		 * icon to display at the end of the menu item.
		 */
		endIcon?: ReactNode;
	}[];
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		profileMenu: {
			display: 'flex',
			flexDirection: 'column',
			padding: theme.spacing(1),
			width: '100%',
		},
		userIcon: {
			height: 40,
			width: 40,
			marginBottom: theme.spacing(1),
		},
		userName: {
			fontSize: 14,
			lineHeight: '130%',
			height: 18,
			color: theme.palette.text.secondary,
		},
		userEmail: {
			fontSize: 12,
			lineHeight: '125%',
			height: 15,
			color: theme.palette.text.secondary,
		},
		menuList: {
			marginTop: theme.spacing(1),
			margin: 0,
			padding: 0,
			overflowY: 'auto',
			width: '100%',
		},
		menuItem: {
			borderRadius: 4,
			'&:hover': {
				backgroundColor:
					// @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
					theme.palette.primary.background || theme.palette.primary.border,
			},
			'&:focus': {
				backgroundColor:
					// @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
					theme.palette.primary.background || theme.palette.primary.border,
			},
			paddingLeft: theme.spacing(2),
			color: theme.palette.text.secondary,
		},
		menuItemText: {
			marginRight: 'auto',
			fontSize: 12,
			height: 15,
			lineHeight: '15px',
		},
		menuItemIcon: {
			height: 28,
			margin: 0,
			justifyContent: 'flex-start',
			color: theme.palette.text.secondary,
			padding: 0,
			'&:disabled': {
				opacity: 0.3,
			},
			'& svg': {
				width: 20, // TODO: is this needed, or are the supplied icons expected to be 20x20 with 4px margin/padding?
				height: 20,
				margin: 4,
			},
			width: 28,
			minWidth: 28,
			'&:first-child': {
				marginRight: theme.spacing(0.5),
			},
		},
	}),
);

export function ProfileMenu(props: ProfileMenuProps) {
	const { name, email, icon, menuItems } = props;
	const classes = useStyles();

	return (
		<div className={classes.profileMenu}>
			{icon && <span className={classes.userIcon}>{icon}</span>}
			{name && <Typography className={classes.userName}>{name}</Typography>}
			{email && <Typography className={classes.userEmail}>{email}</Typography>}
			<ul className={classes.menuList}>
				{menuItems &&
					menuItems.map(item => (
						<MenuItem
							key={item.name}
							onClick={item.onClick}
							disabled={item.disabled}
							className={classes.menuItem}
						>
							{item.icon && (
								<ListItemIcon className={classes.menuItemIcon}>
									{item.icon}
								</ListItemIcon>
							)}
							<ListItemText classes={{ primary: classes.menuItemText }}>
								{item.name}
							</ListItemText>
							{item.endIcon && (
								<ListItemIcon className={classes.menuItemIcon}>
									{item.endIcon}
								</ListItemIcon>
							)}
						</MenuItem>
					))}
			</ul>
		</div>
	);
}
